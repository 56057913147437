
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyB1MckR4A3K7xsyVCX8IHBJNYZc3WjDI0w",
  authDomain: "myportfolio-c65d4.firebaseapp.com",
  projectId: "myportfolio-c65d4",
  storageBucket: "myportfolio-c65d4.appspot.com",
  messagingSenderId: "582196348088",
  appId: "1:582196348088:web:5c9788ea6428945a77a04d",
  measurementId: "G-ERDM9EEGSJ"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);


export const signInWithGoogle = () => signInWithPopup(auth, provider);